





















import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsStepLongGoalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-step-long-goal-view-model';

@Component({ name: 'FlagshipGoalsStepLongGoal' })
export default class FlagshipGoalsStepLongGoal extends Vue {
  step_long_goal_model = Vue.observable(new FlagshipGoalsStepLongGoalViewModel());
}
